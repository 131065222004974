/* Globals Elements */
h1 {
  font-size: 144px;
}
h2 {
  font-size: 64px;
  font-weight: bold;
}
h3 {
  font-size: 40px !important;
}
q {
  font-size: 32px;
}
sub, p {
  font-size: 20px;
}

legend {
  color: $colorPrimaryLight;
}

label {
  font-weight: 700;
}

select, textarea,
input[type=text],input[type=search],input[type=url],input[type=tel],input[type=email],input[type=password],input[type=number]{
  border: none;
  border-radius: 0;
  border-bottom: 1px solid $colorTertiaryDark;
  color: $colorPrimaryLight;
  background-color: $colorTertiaryDark;
  text-indent: 16px;
  font-family: $fontFamilyBase;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  /* Big */
  height: 60px;
  &:not(select), &:not(button) {
    &:focus {
      border-bottom: 2px solid $colorTertiaryLight;
      box-shadow: none;
    }
    &.error {
      border-bottom: 2px solid $colorAlertDanger;
    }
  }
}
input[type=text]::-webkit-input-placeholder, input[type=search]::-webkit-input-placeholder,
input[type=url]::-webkit-input-placeholder, input[type=tel]::-webkit-input-placeholder,
input[type=email]::-webkit-input-placeholder, input[type=password]::-webkit-input-placeholder {color: $colorTertiaryLight; line-height: 15px; font-size: 12px; font-weight: 500; }
input[type=text]:-moz-placeholder, input[type=search]:-moz-placeholder,
input[type=url]:-moz-placeholder, input[type=tel]:-moz-placeholder,
input[type=email]:-moz-placeholder, input[type=password]:-moz-placeholder {color: $colorTertiaryLight; line-height: 15px; font-size: 12px; font-weight: 500; } /* Firefox 18- */
input[type=text] ::-moz-placeholder, input[type=search] ::-moz-placeholder,
input[type=url] ::-moz-placeholder, input[type=tel] ::-moz-placeholder,
input[type=email] ::-moz-placeholder, input[type=password] ::-moz-placeholder {color: $colorTertiaryLight; line-height: 15px; font-size: 12px; font-weight: 500; }  /* Firefox 19+ */
input[type=text] :-ms-input-placeholder, input[type=search] :-ms-input-placeholder,
input[type=url] :-ms-input-placeholder, input[type=tel] :-ms-input-placeholder,
input[type=email] :-ms-input-placeholder, input[type=password] :-ms-input-placeholder {color: $colorTertiaryLight; line-height: 15px; font-size: 12px; font-weight: 500; }

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

a.button,
input[type=button],
button{
  /* Brand Primary is default */
  border: 2px solid $colorBrandPrimary;
  background-color: $colorBrandPrimary;
  color: $colorPrimaryDark;
  path {
    &:not([nofill]) {
        fill: $colorPrimaryDark;
    }
    &[nofill] {
        stroke: $colorPrimaryDark;
    }
  }
  // stroke: $colorPrimaryDark;

  &.big {
    height: 60px;
    font-size: 14px;
    font-weight: bold;
  }
  &.secondary {
    border: 2px solid $colorBrandSecondary;
    background-color: $colorBrandSecondary;
  }
  &.hollow {
    color: $colorPrimaryLight;
    border-color: $colorPrimaryLight;
    background-color: $actionbarBackgroundColor;
  }
  &.narrower {
    padding-left: 12px;
    padding-right: 12px;
  }

  &.dark {
    color: $colorPrimaryLight;
    border-color: $colorQuaternaryDark;
    background-color: $colorQuaternaryDark;
    // margin-right: 16px;
    path {
      &:not([nofill]) {
        fill: $colorPrimaryLight;
      }
      &[nofill] {
        stroke: $colorPrimaryLight;
      }
    }
}

  height: 40px; /* 60px */
  font-family: $fontFamilyBase;
  font-size: 12px; /* 13px */
  font-weight: 500;
  padding: 6px 20px;

  cursor: pointer;
  text-decoration: none;

  &.close {
    height: initial;
  }
}
button[disabled] {
  cursor: not-allowed !important;
  color: lighten($colorPrimaryDark, 30%);
  border-color: lighten($colorBrandPrimary, 10%);
}

[disabled]:not([disabled="false"]) {
    cursor: not-allowed !important;
}

[ng-reflect-router-link],[href]  {
  user-select: none;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  //fill-opacity: 0.5;
  -webkit-box-shadow: 0 0 0px 1000px fade($colorBrandPrimary, 10%) inset;
  -webkit-text-fill-color: $colorSecondaryDark !important;
}

a, a:hover, a:visited {
  //float: right;
  // margin: 8px 12px;
  color: $colorPrimaryLight;
}

// mat-slider {
.mat-slider {
  &.mat-slider:not(.mat-slider-disabled).cdk-mouse-focused .mat-slider-thumb,
  &.mat-slider:not(.mat-slider-disabled).cdk-touch-focused .mat-slider-thumb,
  &.mat-slider:not(.mat-slider-disabled).cdk-program-focused .mat-slider-thumb {
      transform: scale(0.7) !important;
  }
  .mat-slider-track-fill {
      background-color: $colorFreedom !important;
  }
  .mat-slider-thumb, .mat-slider-thumb-label {
      background-color: #FFFFFF !important;
  }
}

mat-icon[svgIcon] {
    width: unset;
    height: unset;
}

// Fix for ckeditor on iOS
[contenteditable] {
    -webkit-user-select: auto;
    user-select: all;
}

// CDK Drag Drop
.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(black, 0.2),
              0 8px 10px 1px rgba(black, 0.14),
              0 3px 14px 2px rgba(black, 0.12),
              0 -5px 5px -3px rgba(black, 0.2),
              0 -8px 10px 1px rgba(black, 0.14),
              0 -3px 14px 2px rgba(black, 0.12);
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.pull-left {
  float: left;
}
.pull-right {
  float: right;
}

/* Globals Width Class */
.w120 {
  width: 120px;
}

/* Globals Color Class */
//:not(.drawing):not(.stamp-icon).guest {
//  color: darken($colorPrimaryLight, 20%);
//}
//:not(.drawing):not(.stamp-icon).none {
//  &:not(.guest) {
//    background-color: $colorSecondaryLight !important;
//    border-color: $colorSecondaryLight !important;
//  }
//  &.guest{
//    background-color: lighten($colorSecondaryLight, 5%) !important;
//    border-color: lighten($colorSecondaryLight, 5%) !important;
//    .timecode {
//      border-color: lighten($colorSecondaryLight, 5%) !important;
//    }
//  }
//}
//:not(.drawing):not(.stamp-icon).orange {
//  &:not(.guest) {
//    background-color: #fb7a47 !important;
//    border-color: #fb7a47 !important;
//  }
//  &.guest{
//    background-color: lighten(#fb7a47, 5%) !important;
//    border-color: lighten(#fb7a47, 5%) !important;
//    .timecode {
//      border-color: lighten(#fb7a47, 5%) !important;
//    }
//  }
//}
//.orange .timecode {
//  border-color: #fb7a47 !important;
//}
//:not(.drawing):not(.stamp-icon).pink{
//  &:not(.guest) {
//    background-color: #f46281 !important;
//    border-color: #f46281 !important;
//  }
//  &.guest{
//    background-color: lighten(#f46281, 5%) !important;
//    border-color: lighten(#f46281, 5%) !important;
//    .timecode {
//      border-color: lighten(#f46281, 5%) !important;
//    }
//  }
//}
//.pink .timecode{
//  border-color: #f46281 !important;
//}

//:not(.drawing):not(.stamp-icon).blue{
//  &:not(.guest) {
//    background-color: #8287f4 !important;
//    border-color: #8287f4 !important;
//  }
//  &.guest{
//    background-color: lighten(#8287f4, 5%) !important;
//    border-color: lighten(#8287f4, 5%) !important;
//    .timecode {
//      border-color: lighten(#8287f4, 5%) !important;
//    }
//  }
//}
//.blue .timecode{
//  border-color: #8287f4 !important;
//}

/* Globals Utility Class */
.logo {
  background: url(/assets/images/logo_white.svg) no-repeat center left;
  background-size: 50%;
}
.hidden{
  display: none;
}

.hollow {
  svg {
    path, rect {
      fill: transparent !important;
    }
  }
}

.left {
  float: left;
}

.right {
  float: right;
}

.button.disabled {
  cursor: not-allowed;
}

:focus {
  outline: none !important;
}

[editable-text]{
  cursor: text;
}
[edit-disabled][disabled]{
  cursor: default;
}

// Dragover
.dragover * {
    pointer-events: none;
}

.toast-container {
    a {
        text-decoration: underline;
    }
}

// Scrollbars
::-webkit-scrollbar {
    width: 8px;
}
::-webkit-scrollbar-track {
    background-color: $colorPrimaryDark;
    // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border: 2px solid $colorSecondaryDark;
    border-radius: 0px;
}

::-webkit-scrollbar-thumb {
    border-radius: 0px;
    // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
    border: 2px solid $colorSecondaryDark;
    border-top: none; border-bottom: none;
    background-color: $colorQuaternaryDark;
}
