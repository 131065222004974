// ---------------------------------------------------------
// Variables
// ---------------------------------------------------------

//#region App Settings
$waveformHeight: 128px;
$waveformHeightMobile: 68px;
$detailWidth: 270px;
$detailWidthMobile: 100px;
//#endregion

// Dark/Background $colorSecondaryDark
$nt2PageBG: #141414;
$nt2OverBG: #1A1A1A;
$nt2OverItemBG: #1F1F1F;
$nt2FocusBG: #262626;
$nt2InputBG: #212121;
$nt2AlertBG: #ED5858;

// $nt2DragOnto: gba(122, 190, 250, 0.1); // #7ABEFA 10%

// Colors
$colorPassion:                        #F26666;
$colorFreedom:                        #FFBE5D;
$colorBalance:                        #8DD964;
$colorSpirit:                         #7ABFFA;
$colorCreativity:                     #BB98E6;

$colorPlatinumGrey:                   #E3E3E1;
$colorNeroGrey:                       #1A1A1A;

$colorTextLight:                      #989898;
$colorTextHighlight:                  #00d1a4;

$colorPrimaryLight:                   #ffffff;
$colorSecondaryLight:                 #8c8c8c;
$colorTertiaryLight:                  #808080;
$colorPrimaryDark:                    $colorNeroGrey;
$colorSecondaryDark:                  #141414;
$colorTertiaryDark:                   #212121;
$colorQuaternaryDark:                 #262626;
$colorQuinaryDark:                    #0D0D0D;

$colorProgressBar:                    #00d1a4;
$colorButtonBorderLight:              #cfcfcf;
$colorButtonBorderDark:               $colorTextHighlight;

$colorBorderLight:                    #cfcfcf;
$colorBorderDark:                     $colorSecondaryLight;

$colorBorderChecked:                  inherit;

$colorBrandPrimary:                   $colorFreedom;
$colorBrandSecondary:                 $colorBalance;

$colorAlertDanger:                    $colorPassion;

$fontFamilyBase:                      Inter, "Theinhardt", "Open Sans", sans-serif;

$pageWidth:                           100vw; //1440px; //1366px;
$pageMinWidth:                        1440px;
$pageBackgroundColor:                 $colorPrimaryDark;

$actionbarHeight:                     80px;
$actionbarMinHeight:                  80px;
$actionbarBackgroundColor:            $colorPrimaryDark;

$actionbarBrandHeight:                28px;
$actionbarBrandWidth:                 188px;

$legend-color:                        $colorPrimaryLight !important;

//#region Responsive
$mobileWidthRef:                      390px;

/**
 * scaleMode:
 *      0 = Scale Up and Down. I.e.: $px = 50px, $width = 400px, returns 25vw
 *      1 = Scale Up Only. I.e.: $px = 50px, $width = 400, return max(50px, 25vw)
 *      -1 = Scale Up Only. I.e.: $px = 50px, $width = 400, return min(50px, 25vw)
 */
@function px2vw($px, $width: $mobileWidthRef, $scaleMode: both) {
    @if $scaleMode == up {
        @return max($px, ($px / $width * 100vw));
    } @else if $scaleMode == down {
        @return min($px, ($px / $width * 100vw));
    } @else {
        @return calc($px / $width * 100vw);
    }
}

@function mvh($vh) {
    @return calc(var(--vh, 1vh) * $vh);
}

//#endregion