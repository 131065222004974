/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Inter, Roboto, "Helvetica Neue", sans-serif; user-select: none; }

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
@import '~ngx-toastr/toastr';
@import "styles/ckeditor";

@import url(https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@500;700&display=swap);
@import "styles/theme";
@import "styles/fonts";
@import "styles/variables";
@import "styles/mixins";
@import "styles/globals"; // @todo Cleanup
