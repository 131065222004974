@font-face {
    font-family: 'Theinhardt';
    src: url(/assets/fonts/Theinhardt-REGULAR.eot);
    src: url(/assets/fonts/Theinhardt-REGULAR.eot?#iefix) format('embedded-opentype'),
    url(/assets/fonts/Theinhardt-REGULAR.woff2) format('woff2'),
    url(/assets/fonts/Theinhardt-REGULAR.woff) format('woff'),
    url(/assets/fonts/Theinhardt-REGULAR.ttf) format('truetype'),
    url(/assets/fonts/Theinhardt-REGULAR.svg#Theinhardt-REGULAR) format('svg');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Theinhardt';
    src: url(/assets/fonts/Theinhardt-MEDIUM.eot);
    src: url(/assets/fonts/Theinhardt-MEDIUM.eot?#iefix) format('embedded-opentype'),
    url(/assets/fonts/Theinhardt-MEDIUM.woff2) format('woff2'),
    url(/assets/fonts/Theinhardt-MEDIUM.woff) format('woff'),
    url(/assets/fonts/Theinhardt-MEDIUM.ttf) format('truetype'),
    url(/assets/fonts/Theinhardt-MEDIUM.svg#Theinhardt-MEDIUM) format('svg');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Theinhardt';
    src: url(/assets/fonts/Theinhardt-BOLD.eot);
    src: url(/assets/fonts/Theinhardt-BOLD.eot?#iefix) format('embedded-opentype'),
    url(/assets/fonts/Theinhardt-BOLD.woff2) format('woff2'),
    url(/assets/fonts/Theinhardt-BOLD.woff) format('woff'),
    url(/assets/fonts/Theinhardt-BOLD.ttf) format('truetype'),
    url(/assets/fonts/Theinhardt-BOLD.svg#Theinhardt-BOLD) format('svg');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'SF Pro Text';
    src: url(/assets/fonts/SFProText-Regular.eot);
    src: url(/assets/fonts/SFProText-Regular.eot?#iefix) format('embedded-opentype'),
        url(/assets/fonts/SFProText-Regular.woff2) format('woff2'),
        url(/assets/fonts/SFProText-Regular.woff) format('woff'),
        url(/assets/fonts/SFProText-Regular.ttf) format('truetype'),
        url(/assets/fonts/SFProText-Regular.svg#SFProText-Regular) format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url(/assets/fonts/SFProText-Semibold.eot);
    src: url(/assets/fonts/SFProText-Semibold.eot?#iefix) format('embedded-opentype'),
        url(/assets/fonts/SFProText-Semibold.woff2) format('woff2'),
        url(/assets/fonts/SFProText-Semibold.woff) format('woff'),
        url(/assets/fonts/SFProText-Semibold.ttf) format('truetype'),
        url(/assets/fonts/SFProText-Semibold.svg#SFProText-Semibold) format('svg');
    font-weight: 600;
    font-style: normal;
}


@font-face {
  font-family: 'Roboto Mono';
  src: url(/assets/fonts/Roboto-Mono-REGULAR.eot);
  src: url(/assets/fonts/Roboto-Mono-REGULAR.eot?#iefix) format('embedded-opentype'),
  url(/assets/fonts/Roboto-Mono-REGULAR.woff2) format('woff2'),
  url(/assets/fonts/Roboto-Mono-REGULAR.woff) format('woff'),
  url(/assets/fonts/Roboto-Mono-REGULAR.ttf) format('truetype'),
  url(/assets/fonts/Roboto-Mono-REGULAR.svg#Roboto-Mono-REGULAR) format('svg');
  font-weight: 400;
  font-style: normal;
}